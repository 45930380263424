;(function () {
  'use strict';

  var isMobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
      BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
      iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
      Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
      Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
    },
      any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  var dialog = function(title, message) {
    $.magnificPopup.open({
      items: {
        src: '<div class="white-popup"><h2>' + title + '</h2><p>' + message + '</p></div>'
      },
      type: 'inline'
    });
  };

  var mobileMenuOutsideClick = function() {

    $(document).click(function (e) {
    var container = $("#gtco-offcanvas, .js-gtco-nav-toggle");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      if ( $('body').hasClass('offcanvas') ) {
        $('body').removeClass('offcanvas');
        $('.js-gtco-nav-toggle').removeClass('active');
      }
    }
    });

  };

  var header = function() {
    $(window).scroll(function(){
      var st = $(window).scrollTop();
      if (st > 50) {
        $('.gtco-nav, .gtco-nav-toggle').addClass('scrolled');
      } else {
        $('.gtco-nav, .gtco-nav-toggle').removeClass('scrolled');
      }
    });

  };

  var navigation = function() {

    $('body').on('click', '#gtco-offcanvas ul a:not([class="external"]), .main-nav a:not([class="external"])', function(event){
      var section = $(this).data('nav-section');
        if ( $('[data-section="' + section + '"]').length ) {
          $('html, body').animate({
            scrollTop: $('[data-section="' + section + '"]').offset().top - 55
          }, 500, 'easeInOutExpo');
         }

         if ($('body').hasClass('offcanvas')) {
        $('body').removeClass('offcanvas');
        $('.js-gtco-nav-toggle').removeClass('active');
         }
       event.preventDefault();
       return false;
    });

  };

  var offcanvasMenu = function() {

    $('body').prepend('<div id="gtco-offcanvas" />');
    $('body').prepend('<a href="#" class="js-gtco-nav-toggle gtco-nav-toggle"><i></i></a>');
    var clone1 = $('.menu-1 > ul').clone();
    $('#gtco-offcanvas').append(clone1);
    var clone2 = $('.menu-2 > ul').clone();
    $('#gtco-offcanvas').append(clone2);

    $('#gtco-offcanvas .has-dropdown').addClass('offcanvas-has-dropdown');
    $('#gtco-offcanvas')
      .find('li')
      .removeClass('has-dropdown');

    // Hover dropdown menu on mobile
    $('.offcanvas-has-dropdown').mouseenter(function(){
      var $this = $(this);

      $this
        .addClass('active')
        .find('ul')
        .slideDown(500, 'easeOutExpo');
    }).mouseleave(function(){

      var $this = $(this);
      $this
        .removeClass('active')
        .find('ul')
        .slideUp(500, 'easeOutExpo');
    });

    $(window).resize(function(){

      if ( $('body').hasClass('offcanvas') ) {

        $('body').removeClass('offcanvas');
        $('.js-gtco-nav-toggle').removeClass('active');

      }
    });
  };

  // Reflect scrolling in navigation
  var navActive = function(section) {
    var $el = $('.main-nav > ul');
    $el.find('li').removeClass('active');
    $el.each(function(){
      $(this).find('a[data-nav-section="' + section + '"]').closest('li').addClass('active');
    });
  };

  var navigationSection = function() {
    var $section = $('section[data-section]');

    $section.waypoint(function(direction) {
      if (direction === 'down') {
        navActive($(this.element).data('section'));
      }
    }, {
      offset: '150px'
    });

    $section.waypoint(function(direction) {
      if (direction === 'up') {
        navActive($(this.element).data('section'));
      }
    }, {
      offset: function() { return -$(this.element).height() + 155; }
    });
  };

  var burgerMenu = function() {
    $('body').on('click', '.js-gtco-nav-toggle', function(event){
      var $this = $(this);

      if ( $('body').hasClass('offcanvas') ) {
        $('body').removeClass('offcanvas');
      } else {
        $('body').addClass('offcanvas');
      }

      $this.toggleClass('active');
      event.preventDefault();
    });
  };

  var contentWayPoint = function() {
    $('.animate-box').waypoint( function( direction ) {
      if (direction === 'down' && !$(this.element).hasClass('animated-fast')) {
        $(this.element).addClass('item-animate');
        setTimeout(function(){

          $('body .animate-box.item-animate').each(function(k) {
            var el = $(this);
            setTimeout(function() {
              var effect = el.data('animate-effect');
              if ( effect === 'fadeIn') {
                el.addClass('fadeIn animated-fast');
              } else if ( effect === 'fadeInLeft') {
                el.addClass('fadeInLeft animated-fast');
              } else if ( effect === 'fadeInRight') {
                el.addClass('fadeInRight animated-fast');
              } else {
                el.addClass('fadeInUp animated-fast');
              }

              el.removeClass('item-animate');
            }, k * 200, 'easeInOutExpo');
          });
        }, 100);
      }
    }, { offset: '85%' } );
  };

  var dropdown = function() {
    $('.has-dropdown').mouseenter(function() {

      var $this = $(this);
      $this
        .find('.dropdown')
        .css('display', 'block')
        .addClass('animated-fast fadeInUpMenu');

    }).mouseleave(function(){
      var $this = $(this);

      $this
        .find('.dropdown')
        .css('display', 'none')
        .removeClass('animated-fast fadeInUpMenu');
    });

  };

  var goToTop = function() {
    $('.js-gotop').on('click', function(event){

      event.preventDefault();

      $('html, body').animate({
        scrollTop: $('html').offset().top
      }, 500, 'easeInOutExpo');

      return false;
    });

    $(window).scroll(function(){
      var $win = $(window);
      if ($win.scrollTop() > 200) {
        $('.js-top').addClass('active');
      } else {
        $('.js-top').removeClass('active');
      }
    });
  };

  var clickHandlers = function() {
    $('.read-more').click(function(event) {
      event.preventDefault();

      $('.read-more-button-container').slideUp('slow');
      $('.read-more-dropdown').fadeIn('slow');
    })
  };

  var formHandler = function() {
    $('.form-purchase').submit(function(event) {
      event.preventDefault();

      var name = $('.form-control#name').val();
      var mail = $('.form-control#email').val();
      var message = $('.form-control#message').val() || '';

      if (!name || !mail) {
        dialog('Figyelem!', 'A név és email cím megadása kötelező.');
        return false;
      }

      $.ajax({
        type: 'POST',
        url: 'mail.php',
        data: {
          name: name,
          mail: mail,
          message: message
        },
        success: function(data, textStatus, request) {
          showComplete(request);
        },
        error: function(request, textStatus, errorThrown) {
          showError();
        }
      });

      return false;
    });
  };

  var initGallery = function() {
    $('a.btn-gallery').click(function(event) {
      event.preventDefault();
      var gallery = $(this).attr('href');

      $(gallery).magnificPopup({
        delegate: 'a',
        type: 'image',
        mainClass: 'mfp-fade',
        gallery: {
          enabled: true,
          tCounter: '<span class="mfp-counter">Immagine:&nbsp;%curr%/%total%</span>'
        },
        image: {
          titleSrc: 'mfp-title'
        }
      }).magnificPopup('open');
    });
  };

  var initVideos = function() {
    $('a.btn-video').magnificPopup({
      type: 'inline',
      preloader: false
    });
  };

  var showComplete = function(data) {
    if (data.responseText === 'OK') {
      dialog('Grazie il suo interessamento!', 'In breve Le rispondiamo!');
    } else {
      showError();
    }
  };

  var showError = function() {
    dialog("Purtroppo c'e un errore!", 'Riprova di nuovo per favore!');
  }

  // Loading page
  var loaderPage = function() {
    $(".gtco-loader").fadeOut("slow");
  };

  $(function() {
    mobileMenuOutsideClick();
    header();
    navigation();
    offcanvasMenu();
    burgerMenu();
    navigationSection();
    contentWayPoint();
    dropdown();
    goToTop();
    clickHandlers();
    formHandler();
    initGallery();
    initVideos();
    loaderPage();
  });
}());
